import TrustpilotStars from '@pretto/bricks/components/iconography/TrustpilotStars'
import Text from '@pretto/bricks/components/typography/Text'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const MiniTrustpilot = ({ highlight, numberOfReviews, score, size }) => (
  <S.Trustpilot href="https://fr.trustpilot.com/review/pretto.fr" rel="noreferrer noopener" target="_blank">
    <S.Rating>
      <TrustpilotStars score={5} size={size} />

      <S.Rate>
        <Text size="small">
          <strong>{score}</strong> sur 5
        </Text>
      </S.Rate>
    </S.Rating>

    <div>
      <Text size="small">
        <strong>{numberOfReviews}</strong> avis sur{' '}
        <Text type="span" size="small" variant={highlight && 'primary-1'}>
          Trustpilot
        </Text>
      </Text>
    </div>
  </S.Trustpilot>
)

MiniTrustpilot.propTypes = {
  highlight: PropTypes.bool,
  numberOfReviews: PropTypes.number,
  score: PropTypes.number,
  size: PropTypes.string,
}

export default memo(MiniTrustpilot)
