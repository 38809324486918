import styled from 'styled-components'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import { Wrapper as W } from '@pretto/bricks/components/layout/Wrapper/styles'

export const Page = styled.div`
  margin-bottom: ${g(3)};
`
export const Wrapper = styled(W)``
export const HeaderBottom = styled(W)`
  @media screen and (min-width: ${breakpoints.tablet}) {
    align-items: flex-start;
    display: flex;
    margin-top: ${g(3)};
  }
`

export const Header = styled.div`
  margin: 0 ${g(2)};
  position: relative;

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin: 0 ${g(3)} ${g(3)};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    margin: ${g(3)} ${g(6)};
    display: flex;
    justify-content: space-between;
  }
`

export const Section = styled.section`
  padding: ${g(2)} 0;
`
export const HeaderSection = styled(Section)`
  background-color: ${({ theme }) => theme.colors.neutral4};

  @media screen and (min-width: ${breakpoints.tablet}) {
    background-image: url('https://res.cloudinary.com/pretto-fr/image/upload/v1611247367/website/page/simulators/decoration-1.png');
    background-repeat: no-repeat;
    background-position: right top;
    background-size: ${g(27)} auto;
  }
`

export const Title = styled.div`
  margin-bottom: ${g(4)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    max-width: ${breakpoints.mobileM};
    padding-right: ${g(3)};
    position: relative;
    z-index: 1;
  }
`

export const CheckInformation = styled.div`
  margin-top: ${g(4)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    align-items: flex-start;
    display: flex;
    margin-top: 0;
    width: 100%;
  }
`
export const CheckItem = styled.div`
  margin-bottom: ${g(2)};

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    flex: 1 1 ${100 / 3}%;
    margin-bottom: 0;
    margin-right: ${g(4)};

    &:last-child {
      margin-right: 0;
    }
  }
`
export const MiniSentences = styled.div`
  @media screen and (min-width: ${breakpoints.laptop}) {
    flex: 0 1 50%;
  }
`
export const Trustpilot = styled.div`
  margin-top: ${g(3)};

  @media screen and (min-width: ${breakpoints.laptop}) {
    display: flex;
    flex-shrink: 0;
  }
`

export const Banks = styled(W)`
  @media screen and (min-width: ${breakpoints.laptop}) {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: ${g(1)} auto;
  }
`
export const BanksTitle = styled.div`
  margin-bottom: ${g(1)};
  text-align: center;
  @media screen and (min-width: ${breakpoints.laptop}) {
    margin-bottom: 0;
    margin-right: ${g(3)};
  }
`
