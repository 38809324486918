import BANKS from '@pretto/config/banks.json'

import shuffle from 'lodash/shuffle'

const LOGOS = [
  'banque_populaire',
  'bnp_paribas',
  'bred',
  'caisse_d_epargne',
  'credit_agricole',
  'credit_du_nord',
  'lcl',
  'societe_generale',
]
const MAX_BANKS_SHOWN = 10

export const getBankBySlug = (slug, format = 'squared') => {
  const bank = BANKS.find(({ value }) => value.includes(slug))
  if (!bank) return { imagePath: `banks/${slug}`, slug }
  const { group, label, logo } = bank
  const name = group || label
  const path = logo[format]
  const extIndex = path.indexOf('.')
  const imagePath = path.substr(1, extIndex - 1)

  return { imagePath, name, path, slug }
}

const banks = (maxShown = MAX_BANKS_SHOWN) =>
  shuffle(LOGOS)
    .splice(0, maxShown)
    .map(slug => getBankBySlug(slug))

export const getBanksList = banks

export default banks
