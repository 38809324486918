import Text from '@pretto/bricks/components/typography/Text'
import BanksLogos from '@pretto/bricks/shared/components/BanksLogos'
import HeaderSentences from '@pretto/bricks/website/shared/components/HeaderSentences'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const Banks = memo(({ banks }) => (
  <S.Section>
    <S.Banks>
      <S.BanksTitle>
        <Text size="small" type="p">
          Nos banques partenaires
        </Text>
      </S.BanksTitle>
      <BanksLogos banks={banks} />
    </S.Banks>
  </S.Section>
))
Banks.propTypes = { banks: PropTypes.array.isRequired }
Banks.displayName = 'Banks'

const HeaderSentencesPage = ({ banks, headerSentencesProps }) => (
  <S.Page>
    <S.Wrapper full>
      <HeaderSentences {...headerSentencesProps} />
    </S.Wrapper>

    {headerSentencesProps.banks && <Banks banks={banks} />}
  </S.Page>
)

HeaderSentencesPage.propTypes = {
  /** List of banks. */
  banks: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    })
  ).isRequired,
  /** Props of `HeaderSentences` component. */
  headerSentencesProps: PropTypes.object,
}

export default HeaderSentencesPage
