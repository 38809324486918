import Icon from '@pretto/bricks/components/iconography/Icon'
import Badge from '@pretto/bricks/components/information/Badge'
import Text from '@pretto/bricks/components/typography/Text'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const CheckInformation = ({ children }) => (
  <S.Container>
    <S.Badge>
      <Badge variant="primary-1" size="large">
        <S.Icon>
          <Icon name="check-2" />
        </S.Icon>
      </Badge>
    </S.Badge>
    <S.Content>
      <Text size="small" type="p">
        {children}
      </Text>
    </S.Content>
  </S.Container>
)

CheckInformation.propTypes = {
  /** Content of component. */
  children: PropTypes.node.isRequired,
}

export default memo(CheckInformation)
