import styled from 'styled-components'
import { g } from '@pretto/bricks/components/layout'

export const Badge = styled.div`
  margin-right: ${g(2)};
`
export const Icon = styled.div`
  font-size: ${g(2)};
`

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
`
export const Content = styled.div`
  padding-top: ${g(0.5)};
`
