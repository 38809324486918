import HeaderSentencesPage from '@pretto/bricks/website/shared/pages/HeaderSentencesPage'

import { getBanksList } from '@pretto/website/src/config/banks'
import { SimulateurCondense as SimulateurCondenseView } from '@pretto/website/src/templates/simulateurCondense/views/SimulateurCondense/SimulateurCondense'
import { useSentences, useTrustScore } from '@pretto/website/src/utilities'

import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

export const SimulateurCondense = ({ data, ...props }) => {
  const banks = useMemo(() => getBanksList(), [])

  const sentencesProps = {
    ...useSentences({
      checks: data.template.headerSentences.checks,
      tabs: data.template.headerSentences.projectKind,
      tabsData: data.template.headerSentences.tabs,
    }),
    isCheckSectionDisplayed: !!data.template.headerSentences.isCheckSectionDisplayed,
  }
  const trustScore = useTrustScore()

  const headerSentencesProps = {
    banks,
    headerSentencesProps: {
      bulletPointsInformation: data.template.headerSentences.bullets?.map(({ content }) => content),
      isBankSectionDisplayed: !!data.template.headerSentences.banks,
      isBulletsSectionDisplayed: !!data.template.headerSentences.isBulletsSectionDisplayed,
      sentencesProps,
      title: data.title,
      trustScore,
    },
  }

  const headerComponent = useMemo(() => <HeaderSentencesPage {...headerSentencesProps} />, [headerSentencesProps])

  return <SimulateurCondenseView {...props} headerComponent={headerComponent} />
}

SimulateurCondense.propTypes = {
  data: PropTypes.shape({
    template: PropTypes.shape({
      headerSentences: PropTypes.shape({
        banks: PropTypes.bool,
        bullets: PropTypes.arrayOf(PropTypes.shape({ content: PropTypes.string.isRequired })).isRequired,
        checks: PropTypes.array,
        isBulletsSectionDisplayed: PropTypes.bool,
        isCheckSectionDisplayed: PropTypes.bool,
        projectKind: PropTypes.string.isRequired,
        tabs: PropTypes.object,
      }).isRequired,
    }).isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
}

export const fragment = graphql`
  fragment SimulateurCondenseTemplate on WpTemplate_SimulateurCondens {
    headerSentences {
      banks
      bullets {
        content
      }
      checks {
        content
      }
      isBulletsSectionDisplayed
      isCheckSectionDisplayed
      projectKind: projectkind
      showEbookBanner: showebookbanner
      tabs {
        capacity {
          ctalabel
          tablabel
        }
        purchase {
          ctalabel
          tablabel
        }
        renegotiation {
          ctalabel
          tablabel
        }
      }
    }
  }
`
