import Divider from '@pretto/bricks/components/dividers/Divider'
import Heading from '@pretto/bricks/components/typography/Heading'
import ResponsiveSSR from '@pretto/bricks/components/utility/ResponsiveSSR'
import MiniSentences from '@pretto/bricks/website/shared/components/MiniSentences'
import MiniTrustpilot from '@pretto/bricks/website/shared/components/MiniTrustpilot'
import CheckInformation from '@pretto/bricks/website/simulators/components/CheckInformation'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const HeaderBottom = memo(({ bulletPointsInformation }) => (
  <S.HeaderBottom>
    <S.CheckInformation>
      {bulletPointsInformation.map((information, i) => (
        <S.CheckItem key={i}>
          <CheckInformation>{information}</CheckInformation>
        </S.CheckItem>
      ))}
    </S.CheckInformation>
  </S.HeaderBottom>
))
HeaderBottom.propTypes = { bulletPointsInformation: PropTypes.array.isRequired }
HeaderBottom.displayName = 'HeaderBottom'

const HeaderSentences = ({
  bulletPointsInformation,
  isBulletsSectionDisplayed = true,
  sentencesProps,
  title,
  trustScore,
  ...props
}) => (
  <S.HeaderSection {...props}>
    <S.Header>
      <S.Title>
        <Heading size="large" type="h1">
          {title}
        </Heading>
        <S.Trustpilot>
          <MiniTrustpilot {...trustScore} size="large" highlight />
        </S.Trustpilot>
      </S.Title>

      <S.MiniSentences>
        <MiniSentences {...sentencesProps} />
      </S.MiniSentences>
    </S.Header>
    {isBulletsSectionDisplayed && (
      <>
        <ResponsiveSSR min="tablet">
          <Divider variant="white" />
        </ResponsiveSSR>
        <HeaderBottom bulletPointsInformation={bulletPointsInformation} />
      </>
    )}
  </S.HeaderSection>
)

HeaderSentences.propTypes = {
  /** Information list. */
  bulletPointsInformation: PropTypes.arrayOf(PropTypes.node).isRequired,
  /** Is bullets secton displayed ? */
  isBulletsSectionDisplayed: PropTypes.bool,
  /** Props of `MiniSentences` component. */
  sentencesProps: PropTypes.shape({
    /** `disabled` state of button. */
    disabled: PropTypes.bool,
    /** `onValidate` function. */
    onValidate: PropTypes.func.isRequired,
    /** `sentences`. */
    sentences: PropTypes.array.isRequired,
  }).isRequired,
  /** Title of page. */
  title: PropTypes.string.isRequired,
  /** Details of trustpilot score. */
  trustScore: PropTypes.shape({
    numberOfReviews: PropTypes.number.isRequired,
    score: PropTypes.number.isRequired,
  }),
}

export default HeaderSentences
