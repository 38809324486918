import { g } from '@pretto/bricks/components/layout'

import PropTypes from 'prop-types'
import { memo } from 'react'

import * as S from './styles'

const BanksLogos = ({ banks, oneLine, ...props }) => (
  <S.BanksLogos oneLine={oneLine} {...props}>
    {banks.map(({ name, path, slug }) => {
      return <S.Image key={slug} path={path} alt={`banque ${name}`} title={name} />
    })}
    <S.MoreBanks>+50</S.MoreBanks>
  </S.BanksLogos>
)

BanksLogos.propTypes = {
  banks: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    })
  ).isRequired,
  moreBanksOpts: PropTypes.shape({
    height: PropTypes.string,
    width: PropTypes.string,
  }),
  oneLine: PropTypes.bool,
}

export default memo(BanksLogos)
