import { breakpoints, g, ng } from '@pretto/bricks/components/layout'
import BaseTypo from '@pretto/bricks/components/typography/BaseTypo'
import BaseImage from '@pretto/bricks/website/shared/components/Image'

import styled, { css } from 'styled-components'

const ContainerCss = css`
  display: block;
  margin: ${g(1)};
  border-radius: ${g(1)};
  width: ${g(7)};
  height: ${g(7)};
  border: 1px solid ${({ theme }) => theme.colors.neutral3};
`

export const MoreBanks = styled(BaseTypo).attrs({ isNumber: true })`
  ${ContainerCss};
  text-align: center;
  line-height: ${g(7)};
  color: ${({ theme }) => theme.colors.neutral2};
`

export const Image = styled(BaseImage).attrs({ options: { height: ng(7), width: ng(7) } })`
  ${ContainerCss};
`

export const BanksLogos = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  width: 100%;

  ${({ oneLine }) =>
    oneLine &&
    css`
      flex-wrap: nowrap;
      justify-content: space-between;

      ${Image} {
        margin: 0;
        margin-right: ${g(2)};
        width: 12%;
        max-width: ${g(10)};
        height: 12%;
        max-height: ${g(10)};

        &:last-child {
          margin-right: 0;
        }

        @media screen and (min-width: ${breakpoints.tablet}) {
          /* 15 logos -> 14 gaps */
          width: ${({ width }) => width}
          height: ${({ height }) => height}

          &:nth-child(n) {
            display: block;
          }
        }
      }
    `}
`
