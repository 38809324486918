import { Grid } from '@pretto/bricks/components/layout/Grid'

import { PostContentContext } from '@pretto/website/src/postTypes/context/PostContent'

import PropTypes from 'prop-types'
import { useContext } from 'react'

import * as S from './SimulateurCondense.styles'

export const SimulateurCondense = ({ headerComponent }) => {
  const { contentWithSidebarComponent, isSidebarMenuActive } = useContext(PostContentContext)

  return (
    <S.Page>
      {headerComponent}

      <Grid>
        <S.Content $isSidebarMenuActive={isSidebarMenuActive}>{contentWithSidebarComponent}</S.Content>
      </Grid>
    </S.Page>
  )
}

SimulateurCondense.propTypes = {
  headerComponent: PropTypes.node.isRequired,
}
