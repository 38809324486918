import styled from 'styled-components'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import { Wrapper as W } from '@pretto/bricks/components/layout/Wrapper/styles'

export const Page = styled.div`
  margin-bottom: ${g(3)};
`
export const Wrapper = styled(W)``

export const Section = styled.section`
  padding: ${g(2)} 0;
`
export const HeaderSection = styled(Section)`
  background-color: ${({ theme }) => theme.colors.neutral4};

  @media screen and (min-width: ${breakpoints.tablet}) {
    background-image: url('https://res.cloudinary.com/pretto-fr/image/upload/v1611247367/website/page/simulators/decoration-1.png');
    background-repeat: no-repeat;
    background-position: right top;
    background-size: ${g(27)} auto;
  }
`
export const Banks = styled(W)`
  @media screen and (min-width: ${breakpoints.laptop}) {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: ${g(1)} auto;
  }
`
export const BanksTitle = styled.div`
  margin-bottom: ${g(1)};
  text-align: center;
  @media screen and (min-width: ${breakpoints.laptop}) {
    margin-bottom: 0;
    margin-right: ${g(3)};
  }
`
